export function createRoutes() {
  return [
    {
      path: 'compare',
      component: () => import(/* webpackChunkName: "compareChrome" */ '@/compare/CompareChrome.vue'),
      children: [
        {
          path: '',
          name: 'Compare Station',
          component: () => import(/* webpackChunkName: "compareSummary" */ '@/compare/CompareSummary.vue'),
        },
      ],
    },
  ];
}

export default {
  createRoutes,
};

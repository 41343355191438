import Vue from 'vue';
import Vue2TouchEvents from 'vue2-touch-events';
import VueGtag from 'vue-gtag';

import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: { id: 'G-2B5WQXPV82' },
}, router);

Vue.use(Vue2TouchEvents, {
  disableClick: false,
  touchClass: '',
  tapTolerance: 10,
  touchHoldTolerance: 400,
  swipeTolerance: 30,
  longTapTimeInterval: 400,
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

export function createRoutes() {
  return [
    {
      path: 'prime-time',
      component: () => import(/* webpackChunkName: "PrimeTimeChrome" */ '@/primeTime/PrimeTimeChrome.vue'),
      children: [
        {
          path: '',
          name: 'Compare Primetime',
          component: () => import(/* webpackChunkName: "PrimeTimeSummary" */ '@/primeTime/PrimeTimeSummary.vue'),
        },
      ],
    },
  ];
}

export default {
  createRoutes,
};

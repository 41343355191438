export function createRoutes() {
  return [
    {
      path: 'report',
      component: () => import(/* webpackChunkName: "reportChrome" */ '@/report/ReportChrome.vue'),
      children: [
        {
          path: '',
          name: 'Report by Station',
          component: () => import(/* webpackChunkName: "reportSummary" */ '@/report/ReportSummary.vue'),
        },
      ],
    },
  ];
}

export default {
  createRoutes,
};
